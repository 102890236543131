import React from 'react';
import WordRotate from "../Componentes/Word_rotate.tsx";
import AnimatedGridPattern from "../Componentes/GridPattern";
import styles from "./Home_module.css"; // Asegúrate de que la ruta sea correcta


const Home = () => {

  const words=["Estamos actualizando nuestro sitio","Nos vemos muy pronto"];
  return (
    <div className="estilos">
      <WordRotate className="tittle" words={words}></WordRotate>
      <AnimatedGridPattern />
    </div>
    
  );
};

export default Home;
